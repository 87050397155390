exports.components = {
  "component---src-sites-type-background-kchbwroclaw-templates-add-domain-tsx": () => import("./../../../src/sites/typeBackground/kchbwroclaw/templates/AddDomain.tsx" /* webpackChunkName: "component---src-sites-type-background-kchbwroclaw-templates-add-domain-tsx" */),
  "component---src-sites-type-background-kchbwroclaw-templates-article-tsx": () => import("./../../../src/sites/typeBackground/kchbwroclaw/templates/Article.tsx" /* webpackChunkName: "component---src-sites-type-background-kchbwroclaw-templates-article-tsx" */),
  "component---src-sites-type-background-kchbwroclaw-templates-contact-tsx": () => import("./../../../src/sites/typeBackground/kchbwroclaw/templates/Contact.tsx" /* webpackChunkName: "component---src-sites-type-background-kchbwroclaw-templates-contact-tsx" */),
  "component---src-sites-type-background-kchbwroclaw-templates-home-tsx": () => import("./../../../src/sites/typeBackground/kchbwroclaw/templates/Home.tsx" /* webpackChunkName: "component---src-sites-type-background-kchbwroclaw-templates-home-tsx" */),
  "component---src-sites-type-background-kchbwroclaw-templates-not-found-tsx": () => import("./../../../src/sites/typeBackground/kchbwroclaw/templates/NotFound.tsx" /* webpackChunkName: "component---src-sites-type-background-kchbwroclaw-templates-not-found-tsx" */),
  "component---src-sites-type-background-kchbwroclaw-templates-search-tsx": () => import("./../../../src/sites/typeBackground/kchbwroclaw/templates/Search.tsx" /* webpackChunkName: "component---src-sites-type-background-kchbwroclaw-templates-search-tsx" */),
  "component---src-sites-type-background-kchbwroclaw-templates-terms-tsx": () => import("./../../../src/sites/typeBackground/kchbwroclaw/templates/Terms.tsx" /* webpackChunkName: "component---src-sites-type-background-kchbwroclaw-templates-terms-tsx" */)
}

